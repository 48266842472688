/* The Modal (background) */
.modal {
	display: block; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	padding-top: 100px; /* Location of the box */
	padding-bottom: 100px;
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
  
/* Modal Content */
.modal-content {
	background-color: #fefefe;
	margin: auto;
	padding: 5px;
	border: 1px solid #888;
    width: 500px;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    /* Modal Content */
    .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 5px;
        border: 1px solid #888;
        width: 320px;
        border-radius: 10px;
    }
}

h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display:inline-block;
    margin: 0px 0px 0px 0px !important; 
    color: black;
}

button {
    display: inline-block;
    background: #09345B;
    color: #fff;
    font-size: 14px;
    width: 200px;
    height: 40px;
    font-weight: 500;
    border-radius: 10px;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease;
    margin: 5px 5px 5px 5px;
}

p {
    margin-bottom: 0 !important;
}