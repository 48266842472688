.wrapper {
    overflow: auto;
    border-radius: 6px;
    border: 1px solid #CCCCCC;
}

.center-summary {
    margin: auto;
    text-align: center;
}

.summary {
    width: 100%;
    height: 180px;
}

.summary button {
    display: inline-block;
    background: #09345B;
    color: #fff;
    font-size: 14px;
    width: 40%;
    font-weight: 500;
    border-radius: 10px;
    /* padding: 15px 30px 15px 30px; */
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease;
    margin-top: 20px;
    margin-bottom: 20px;
}

.table-summary {
    border-spacing: 0;
    border-collapse: collapse;
    border-style: hidden;
    background-color: transparent;
    width: 100%;
    font-size: 16px;
    height: 181px;
}

@media screen and (max-width: 420px) {
    .table-summary {
        font-size: 12px;
    }
}

.table-summary thead {
    color: white;
    background-color: #09345B;
}

.table-summary tbody {
    background-color: #F9F9F8;
}

.table-summary tr {
    border-bottom: 1pt solid lightgray;
    text-align: center;
    height: 30px;
}

.table-summary-border-right {
    border-right: 1pt solid lightgray;
}