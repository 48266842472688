.movement {
    text-align: center;
}

.wrapper-movement {
    overflow: auto;
    border-radius: 6px;
    border: 1px solid #CCCCCC;
    height: 437px;
}

.table-movement {
    border-spacing: 0;
    border-collapse: collapse;
    border-style: hidden;
    background-color: transparent;
    width: 100%;
    font-size: 16px;
}

.table-movement thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.table-movement tbody {
    display: block;
    max-height: 300px;
    overflow-y: scroll;
}

@media screen and (max-width: 420px) {
    .table-movement {
        font-size: 12px;
    }
    .wrapper-movement {
        height: 401px;
    }
}

.table-movement thead {
    width: 100%;
    text-align: left;
    color: gray;
    background-color: #F9F9F8;
    border-bottom: none;
}

.table-movement tbody {
    background-color: #F9F9F8;
    height: 400px;
}

.table-movement tfoot {
    color: white;
    background-color: #09345B;
    text-align: right;
}

.table-movement tbody tr {
    border-bottom: 1pt solid lightgray;
    text-align: left;
    height: 30px;
}

.table-movement-border-right {
    border-right: 1pt solid lightgray;
}

.expenses {
    color: black;
    text-align: center;
    /* font-weight: bold; */
}

.income {
    color: black;
    text-align: center;
    /* font-weight: bold; */
}

.yield {
    color: black;
    text-align: center;
    /* font-weight: bold; */
}

tbody::-webkit-scrollbar {
    -webkit-appearance: none;
}

tbody::-webkit-scrollbar:vertical {
    width: 11px;
}

tbody::-webkit-scrollbar:horizontal {
    height: 11px;
}

tbody::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}
