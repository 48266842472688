.wrapper {
    overflow: auto;
    border-radius: 6px;
    border: 1px solid #CCCCCC;
}

.table-period {
    border-spacing: 0;
    border-collapse: collapse;
    border-style: hidden;
    background-color: transparent;
    width: 100%;
    margin: auto;
    font-size: 16px;
}

@media screen and (max-width: 420px) {
    .table-period {
        font-size: 12px;
    }
}

.table-period thead {
    color: white;
    background-color: #09345B;
}

.table-period tbody {
    background-color: #F9F9F8;
}

.table-period tr {
    border-bottom: 1pt solid lightgray;
    text-align: center;
    height: 30px;
}

.table-period-border-right {
    border-right: 1pt solid lightgray;
}

.table-period button {
    display: inline-block;
    background: #09345B;
    color: #fff;
    font-size: 14px;
    width: 90%;
    height: 35px;
    font-weight: 500;
    border-radius: 10px;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease;
    margin: 5px 5px 5px 5px;
}