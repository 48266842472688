.nav-bar {
    background-color: #103352;
    border-radius: 0;
}

.nav-bar img {
    height: 60px;
}

.nav-bar-title {
    color: white !important;
}

.account-button {
    color: white !important;
}

.button {
    background-color: #09345B;
}

.nav-bar button {
    background-color: transparent;
    border-color: transparent;
}

.nav-bar button:focus {
    background-color: transparent;
    border-color: transparent;
}

.nav-bar button:hover {
    background-color: transparent;
    border-color: transparent;
}

.nav-bar button:active {
    background-color: transparent;
    border-color: transparent;
}

.nav-bar navbar-toggler-icon {
    color: white;
}