.button-login {
    height: 40px;
    width: 140px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.loading {
    height: 20px;
    width: 20px;
    color: white !important;
}

.alert-dialog {
    position: absolute;
    top: 10px;
    right: 10px;
}

.message {
    margin-bottom: 0;
}
  
h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display:inline-block;
    margin: 40px 8px 10px 8px; 
    color: #cccccc;
}
  
body > #root > div {
    height: 100vh;
}

input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px #f6f7f8 inset; /* Change the color to your own background color */
    -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #f6f7f8 inset;/*your box-shadow*/
    -webkit-text-fill-color: #333;
}

.login-form input {
    font-weight: 500 !important;
    border-radius: 5px;
    font-size: 16px;
    border: 1px solid #f6f7f8;
    background: #f6f7f8;
    color: #000 !important;
    outline: none;
    padding: 14px;
    margin: 10px 0px;
    transition-duration: 0.4s;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    width: 85%;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
}

.login-form button {
    display: inline-block;
    background: #09345B;
    color: #fff;
    font-size: 14px;
    width: 40%;
    font-weight: 500;
    border-radius: 10px;
    padding: 15px 30px 15px 30px;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease;
    margin-top: 15px;
    margin-bottom: 15px;
}

.login-form button:hover {
    background: #09345B;
}
  
.gradient {
    background-color: #09345B;
    /* background: rgb(188,170,125); */
    /* background: linear-gradient(90deg, rgba(188,170,125,1) 0%, rgba(0,0,0,0.427608543417367) 100%); */
}
  
.center {
    margin: auto;
    width: 120px;
    height: 120px;
    background-color: #f6f6f6;
    border-radius: 60px;
}

.logo-container {
    margin-top: 10px;
}
  
/* STRUCTURE */
.wrapper-login {
    display: flex;
    align-items: center;
    flex-direction: column; 
    justify-content: center;
    width: 100%;
    min-height: 100%;
}
  
#formContent {
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    position: relative;
    padding: 0px;
    -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
    box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
    text-align: center;
}
  
#formFooter {
    background-color: #f6f6f6;
    border-top: 1px solid #dce8f1;
    padding: 25px;
    text-align: center;
    -webkit-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
}

.logoStyle {
    width:80px;
    height:80px;
    margin-top:12px;
}

.formContainer {
    padding-top:20px;
}

.formStyle {
    margin-top:20px;
}